<template>
    <div class="event">
        <slot name="icon">
            <img class="event__icon" :src="sportIcon" v-if="sportIcon" />
        </slot>

        <div class="event_info">
            <slot name="content">
                <span>
                    {{ title || __('Našli sme ti parťákov') }}
                </span>
                <!-- prettier-ignore -->
                <strong>
                    {{ item.event.sport_id ? sportStore.getById(item.event.sport_id)?.name : __('Akýkoľvek šport') }}
                    -
                    {{ item.termine.date ? useDateFormatted(item.termine.date) : __('Kedykoľvek') }}
                </strong>
            </slot>
        </div>

        <slot name="actions">
            <FormButton
                visual="primary"
                size="xs"
                class="ms-auto"
                v-if="cancelClick"
                @click.prevent.stop="
                    useLazyClick($event, () => cancelClick(item))
                "
            >
                {{ __('Zrušiť') }}
            </FormButton>
        </slot>
    </div>
</template>

<script setup>
const sportStore = useSportStore();

const props = defineProps({
    item: {},
    cancelClick: {},
    title: {},
});

const sportIcon = computed(() => {
    return props.item
        ? sportStore.getById(props.item.event.sport_id)?.iconThumbnail
        : null;
});
</script>

<style lang="scss" scoped>
.event {
    border-radius: 0 1rem 1rem 0;
    border: 1px solid #ddd;
    border-left: 2px solid var(--ion-color-primary);
    padding: 1.6rem;
    display: flex;
    align-items: center;
    gap: 1.2rem;
    background: var(--ion-background-color);
}

.event__icon {
    width: 2.4rem;
    height: 2.4rem;
    filter: invert(34%) sepia(62%) saturate(3160%) hue-rotate(351deg)
        brightness(99%) contrast(86%);
}

.event_info {
    display: flex;
    flex-direction: column;

    span {
        font-size: 0.9rem;
        font-weight: 300;
    }

    strong {
        font-family: var(--secondary-font-family);
        font-size: 1.6rem;
        font-weight: 800;
    }
}
</style>
